<template>
    <div class="shop-area pt-140 pb-90">
        <loading :loading-show="loadingShow"/>
        <div class="container">
            <div class="row flex-row-reverse">
                <div class="col-lg-12">
                    <div class="shop-topbar-wrapper">
                        <div class="shop-topbar-left">
                            <div class="view-mode nav">
                                <a class="active" href="#shop-1" data-toggle="tab"><i class="la la-th"></i></a>
                                <a href="#shop-2" data-toggle="tab"><i class="la la-list-ul"></i></a>
                            </div>
                        </div>
                        <div class="product-sorting-wrapper">
                            <div class="product-shorting shorting-style">
                                <label>Sort:</label>
                                <select v-model="sort">
                                    <option value="unsorted"> unsorted</option>
                                    <option value="price"> Price</option>
                                    <option value="size"> Size</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="shop-bottom-area">
                        <div class="tab-content jump">

                            <!-- 相册型 -->
                            <div id="shop-1" class="tab-pane active">


                                <div class="row" v-if="list.length > 0">
                                    <div v-for="item in list" :key="item.id"
                                         class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <div class="product-wrap mb-35">
                                            <div class="product-img mb-15">
                                                <router-link
                                                        :to="{name: 'Detail', query: {category: item.category, id: item.id}}">
                                                    <img :src="item.imgSrc[0]" alt=""></router-link>
                                            </div>
                                            <div class="product-content">
                                                <div class="product-content-badges" v-if="item.badge">
                                                    <span class="shop-list-badge font-dec"
                                                          v-for="(badge, index) in item.badge"
                                                          :key="index">{{badge}}</span>
                                                </div>
                                                <h4>
                                                    <router-link
                                                            :to="{name: 'Detail', query: {category: item.category, id: item.id}}">
                                                        {{`Model: ${item.name}`}}
                                                    </router-link>
                                                </h4>
                                                <span>
                                                  <span v-for="(size, index) in item.size" :key="index">{{ size }} <template
                                                          v-if="index !== item.size.length - 1"> , </template> </span>
                                                </span>
                                                <div class="price-addtocart">
                                                    <div class="product-price">
                                                        <span>PHP <span v-for="(price, index) in item.price"
                                                                        :key="index">{{ price }} <template
                                                                v-if="index !== item.price.length - 1"> / </template> </span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--无产品-->
                                <div style="text-align: center;" v-else> <b>Temporarily out of stock!</b> </div>

                            </div>
                            <!--/. 相册型 -->

                            <!-- 列表型 -->
                            <div id="shop-2" class="tab-pane" v-if="list.length>0">

                                <div class="shop-list-wrap mb-30" v-for="item in list" :key="item.id">
                                    <div class="row">
                                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                            <div class="product-list-img">
                                                <router-link
                                                        :to="{name: 'Detail', query: {category: item.category, id: item.id}}">
                                                    <img :src="item.imgSrc[0]" alt=""></router-link>
                                                <div class="product-list-quickview">
                                                    <a data-toggle="modal" data-target="#exampleModal"
                                                       title="Quick View" href="#"><i class="la la-plus"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-8 col-lg-7 col-md-6 col-sm-6">
                                            <div class="shop-list-content">
                                                <h4>
                                                    <router-link
                                                            :to="{name: 'Detail', query: {category: item.category, id: item.id}}">
                                                        {{`Model: ${item.name}`}}
                                                    </router-link>
                                                </h4>
                                                <div class="pb-20"><span><span v-for="(size, index) in item.size"
                                                                               :key="index">{{ size }} <template
                                                        v-if="index !== item.size.length - 1"> , </template> </span></span>
                                                </div>
                                                <div class="pro-list-price">
                                                    <span>PHP {{item.price}}</span>
                                                    <span class="old-price">${{item.oldPrice}}</span>
                                                </div>
                                                <div class="" v-if="item.badge">
                                                    <span class="shop-list-badge font-dec"
                                                          v-for="(badge, index) in item.badge"
                                                          :key="index">{{badge}}</span>
                                                </div>
                                                <p>{{ item.description }}</p>
                                                <div class="product-list-action">
                                                    <a title="Wishlist" href="#"><i class="la la-heart-o"></i></a>
                                                    <a title="Compare" href="#"><i class="la la-retweet"></i></a>
                                                    <a title="Add To Cart" href="#"><i class="la la-shopping-cart"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                              <!--无产品-->
                            <div class="tab-pane" style="text-align: center;" v-else> <b>Temporarily out of stock!</b> </div>
                            <!--/. 列表型 -->

                            <!-- pagenation -->
                            <!--<div class="pagination-style text-center">
                              <ul>
                                <li><a class="prev" href="#"><i class="la la-angle-left"></i></a></li>
                                <li><a href="#">01</a></li>
                                <li><a href="#">02</a></li>
                                <li><a class="active" href="#">03</a></li>
                                <li><a href="#">04</a></li>
                                <li><a href="#">05</a></li>
                                <li><a href="#">06</a></li>
                                <li><a class="next" href="#"><i class="la la-angle-right"></i></a></li>
                              </ul>
                            </div>-->
                            <!--/. page -->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Loading from "../components/Loading";

    export default {
        name: 'Shop',
        data() {
            return {
                list: [],
                sort: 'unsorted',
                loadingShow: false,
                category: '',
            }
        },
        components: {
            Loading
        },
        watch: {
            sort: {
                handler(newV) {
                    if (newV) {
                        switch (newV) {
                            case 'price':

                                this.loadingShow = true;

                                setTimeout(() => {
                                    this.loadingShow = false;
                                    this.list.sort((a, b) => {
                                        return parseInt(a.price) - parseInt(b.price);
                                    });
                                }, 1000)

                                break;
                            case 'size':
                                this.loadingShow = true;

                                setTimeout(() => {
                                    this.loadingShow = false;
                                    this.list.sort((a, b) => {
                                        return parseInt(a.size) - parseInt(b.size);
                                    });
                                }, 1000)

                                break;
                            case 'unsorted':
                                this.loadingShow = true;
                                setTimeout(() => {
                                    this.loadingShow = false;
                                    this.getList(this.category);
                                }, 1000)
                                break;
                        }
                    }
                },
                immediate: true
            },
            '$route.query': {
                handler(newV) {
                    let {category} = newV
                    this.category = category
                    this.getList(this.category)

                },
                immediate: true,
                deep: true
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 类型商品靠前
             * */
            aheadType(type) {
                let thisTypeList = this.list.filter((currentItem, index) => {
                    return currentItem.type === type
                })
                let otherTypeList = this.list.filter((currentItem, index) => {
                    return currentItem.type !== type
                })
                this.list = thisTypeList.concat(otherTypeList)

            },
            /**
             * 获取列表
             * */
            getList(category) {
                this.$HTTP.get(`./assets/data/${category}.json?_=${new Date().getTime()}`).then(res => {
                    this.list = res.data;
                    let aheadType = this.$route.query.aheadType;
                    if (aheadType) {
                        //数据重构，aheadType 类型的商品靠前
                        this.aheadType(aheadType)
                    }
                })
            }
        }
    }
</script>
