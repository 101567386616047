<template>
  <div class="">
    <!-- 路由入口 -->
    <header class="header-area transparent-bar sticky-bar" style="background-color: #fff;">
      <div class="main-header-wrap">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-lg-3">
              <div class="logo mt-40">
                <a href="/"><img src="../../public/assets/images/logo/logo-1.png" alt="logo"></a>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9">
              <div class="main-menu menu-common-style menu-lh-1 menu-other-style header-right-wrap">
                <!-- PC 导航 -->
                <nav>
                  <ul>
                    <li :class="{active: $route.name === 'Home'  || category === ''}"><router-link :to="{name: 'Home'}">Home</router-link></li>
                    <li :class="{active: category === 'sofa' }"><router-link :to="{name: 'Shop', query: { category: 'sofa' }}">Sofa</router-link></li>
                    <li :class="{active: category === 'bed' }"><router-link :to="{name: 'Shop', query: { category: 'bed' }}">Bed</router-link></li>
                    <li :class="{active: category === 'tvstand' }"><router-link :to="{name: 'Shop', query: { category: 'tvstand' }}">TV stand</router-link></li>
                    <li :class="{active: category === 'others' }"><router-link :to="{name: 'Shop', query: { category: 'others' }}">Others</router-link></li>
                  </ul>
                </nav>
                <!--/. PC 导航 -->
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="header-small-mobile">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6">
              <div class="mobile-logo">
                <a href="/">
                  <img alt="" src="../../public/assets/images/logo/logo-1.png" style="width: 100px; height: auto;">
                </a>
              </div>
            </div>
            <div class="col-6">
              <div class="header-right-wrap">
                <div class="mobile-off-canvas">
                  <a class="mobile-aside-button" href="javascript:void(0)"><i class="la la-navicon la-2x"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="mobile-off-canvas-active">
      <a class="mobile-aside-close"><i class="la la-close"></i></a>
      <div class="header-mobile-aside-wrap">

        <div class="mobile-menu-wrap">
          <!-- mobile menu start -->
          <div class="mobile-navigation">
            <!-- mobile menu navigation start -->
            <nav>
              <ul class="mobile-menu">
                <li :class="{active: $route.name === 'Home' || category === ''}"><router-link :to="{name: 'Home'}">Home</router-link></li>
                <li :class="{active: category === 'sofa' }"><router-link :to="{name: 'Shop', query: { category: 'sofa' }}">Sofa</router-link></li>
                <li :class="{active: category === 'bed' }"><router-link :to="{name: 'Shop', query: { category: 'bed' }}">Bed</router-link></li>
                <li :class="{active: category === 'tvstand' }"><router-link :to="{name: 'Shop', query: { category: 'tvstand' }}">TV stand</router-link></li>
                <li :class="{active: category === 'others' }"><router-link :to="{name: 'Shop', query: { category: 'others' }}">Others</router-link></li>
              </ul>
            </nav>
            <!-- mobile menu navigation end -->
          </div>
          <!-- mobile menu end -->
        </div>
        <div class="mobile-social-wrap">
          <a href="https://www.facebook.com/sofaphfurniture/" target="_blank"><i class=" ti-facebook "></i></a>
          <a href="https://www.instagram.com/sofaph_com/" target="_blank"><i class=" ti-instagram "></i></a>
          <!--<a class="facebook" href="#"><i class="ti-facebook"></i></a>
          <a class="twitter" href="#"><i class="ti-twitter-alt"></i></a>
          <a class="pinterest" href="#"><i class="ti-pinterest"></i></a>
          <a class="instagram" href="#"><i class="ti-instagram"></i></a>
          <a class="google" href="#"><i class="ti-google"></i></a>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'WebHeader',
  data(){
    return {
      category: ''
    }
  },
  props: {

  },
  mounted() {
    this.headerInit();
    this.headermobileAside();
    $(".mobile-menu li").on({
      click: function() {
        $('.mobile-aside-close').trigger('click')
      }
    })
  },
  watch: {
    '$route.query': {
      handler(newV) {
        let {category} = newV
        this.category = category
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    headerInit() {
      let header = $('.sticky-bar');
      let win = $(window);
      win.on('scroll', function() {
        var scroll = win.scrollTop();
        if (scroll < 200) {
          header.removeClass('stick');
        } else {
          header.addClass('stick');
        }
      });
    },
    headermobileAside() {
      var navbarTrigger = $('.mobile-aside-button'),
              endTrigger = $('.mobile-aside-close'),
              container = $('.mobile-off-canvas-active'),
              wrapper = $('.main-wrapper');

      wrapper.prepend('<div class="body-overlay"></div>');

      navbarTrigger.on('click', function(e) {
        e.preventDefault();
        container.addClass('inside');
        wrapper.addClass('overlay-active');
      });

      endTrigger.on('click', function() {
        container.removeClass('inside');
        wrapper.removeClass('overlay-active');
      });

      $('.body-overlay').on('click', function() {
        container.removeClass('inside');
        wrapper.removeClass('overlay-active');
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
  @import "../../public/assets/css/variabls";
  @import "../../public/assets/css/header";
</style>
