<template>
  <div class='mock'  v-if="loadingShow">
    <div class='main'>
      <img src="../../public/assets/images/loading.gif" height="32" width="32"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Loading',
    props: {
      loadingShow: {
        default: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .mock {
    width:100vw;
    height:100vh;
    top:0;
    left: 0;
    position:fixed;
    z-index: 100;
    background-color: rgba(255,255,255,0.9);
  }
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
  }
</style>