import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false

const HTTP = axios.create({
  //baseURL: baseUrl,
  timeout: 6000,
  headers: {
    'X-Custom-Header': 'foobar',
    common:{

    },
    post:{
      'Content-Type' : "application/x-www-form-urlencoded",
    }
  }
});

HTTP.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log(error)
      if (error.response.status === 401) {
        source.cancel(); // 取消其他正在进行的请求
        alert('未授权，请重新登录！');
        sessionStorage.removeItem('token')
        window.location.href = '/'
      } else if (error.response.status === 404) {
        //alert('404，接口未找到！')
      } else if (error.response.status === 500) {
        //alert('500，应用程序错误！')
      }
      if (axios.isCancel(error)) { // 取消请求的情况下，终端Promise调用链
        return new Promise(() => {});
      } else {
        return Promise.reject(error);
      }
    }
);

Vue.prototype.$HTTP = HTTP;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
//
// const app = Vue.createApp({
//   router,
//   render: h => h(App)
// });
// app.use(HTTP)
// app.mount('#app')