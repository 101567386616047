<template>
  <div id="app">
    <div class="main-wrapper">

      <web-header />

      <router-view/>

      <web-footer />

    </div>
  </div>
</template>


<script>
  import WebHeader from "./components/WebHeader";
  import WebFooter from "./components/WebFooter";
  export default {
    components: {WebFooter, WebHeader},
    watch:{
      $route: {
        handler(to) {
          if (to) {
            window.scrollTo({top: 0})
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
