<template>
  <div class="">
    <!-- footer -->
    <footer class="footer-area">
      <div class="footer-top bg-gray pt-120 pb-85">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-12 col-sm-6">
              <div class="footer-widget mb-30">
                <a href="#"><img src="../../public/assets/images/logo/logo-1.png" alt="logo"></a>
                <div class="footer-social">
                  <span>Follow us:</span>
                  <ul>
                    <li><a href="https://www.facebook.com/sofaphfurniture/" target="_blank"><i class=" ti-facebook "></i></a></li>
                    <li><a href="https://www.instagram.com/sofaph_com/" target="_blank"><i class=" ti-instagram "></i></a></li>
                    <!--<li><a href="#"><i class=" ti-twitter-alt "></i></a></li>
                    <li><a href="#"><i class=" ti-pinterest "></i></a></li>
                    <li><a href="#"><i class=" ti-vimeo-alt "></i></a></li>-->
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 col-sm-6">
              <div class="footer-widget mb-30">
                <div class="footer-title">
                  <h3>terms and conditons</h3>
                </div>
                <div class="subscribe-style ">
                  <p style="margin-bottom: 15px;"><b>Showroom hours:</b> <br>Monday to Sunday   10 am to 6pm</p>
                  <p style="margin-bottom: 15px;"><b>Contact number: </b><br>09670030634  (Book for appointment)</p>
                  <p style="margin-bottom: 15px;"> <b>Email:</b> <br>sales@sofaph.com</p>
                  <p> <B>Sofa warehouse address:</B> <br>BMK E-commerce Park, 555 Carlos Palanca, San Miguel, Manila （500 meter away Malancanna Palace)</p>

                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12 col-sm-6">
              <div class="footer-widget mb-30 footer-mrg-hm1">
                <div class="footer-title">
                  <h3>clause</h3>
                </div>
                <div class="footer-list">
                  <ul>
                    <li v-for="(item, index) in clause"><a @click="current = index " href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal" >{{item.title}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-12 col-sm-6">
              <div class="footer-widget mb-30">
                <div class="footer-title">
                  <h3>Product</h3>
                </div>
                <div class="footer-list">
                  <ul>
                    <li><router-link :to="{name: 'Shop', query: { category: 'sofa' }}">Sofa</router-link></li>
                    <li><router-link :to="{name: 'Shop', query: { category: 'bed' }}">Bed</router-link></li>
                    <li><router-link :to="{name: 'Shop', query: { category: 'tvstand' }}">TV stand</router-link></li>
                    <li><router-link :to="{name: 'Shop', query: { category: 'others' }}">Others</router-link></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="footer-bottom bg-gray-2 ptb-20">
        <div class="container">
          <div class="copyright text-center">
            <p>Copyright &copy; 2022 Sofaph.com All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
    <!--/. footer -->

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <b v-if="clause">{{clause[current].title}}</b>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">x</span></button>
          </div>
          <div class="modal-body">
            <div v-if="clause" class="p-2" v-html="clause[current].content">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
  </div>

</template>

<script>
export default {
  name: 'WebFooter',
  data() {
    return {
      clause: null,
      current: 0
    }
  },
  mounted() {
    this.getClause()
  },
  methods: {
    /**
     *  获取条款数据
    */
    getClause() {
      this.$HTTP.get('./assets/data/clause.json').then( res => {
        let data = res.data
        if (data) {
          this.clause = data
        }
      })
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
  @import "../../public/assets/css/variabls";
  @import "../../public/assets/css/footer";
</style>
